import {
    updateCmsPage,
    updateCmsPageLoading,
} from 'SourceStore/Cms/Cms.action';

import { CmsActionType } from './Cms.type';

export {
    updateCmsPage,
    updateCmsPageLoading
}

/** @namespace Ennero/Store/Cms/Action/updateCmsBlock */
export const updateCmsBlock = (cmsBlock) => ({
    type: CmsActionType.UPDATE_CMS_BLOCK,
    cmsBlock,
});

