import { FieldFileContainer as SourceFieldFileContainer } from 'SourceComponent/FieldFile/FieldFile.container';

/** @namespace Ennero/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
  
    containerProps() {
        const {
            events,
            attr: {
                autoComplete,
                ...attr
            } = {},
            setRef,
            resetFieldValue,
            placeHolder
        } = this.props;
        const { fileName, isLoading, value } = this.state;
    

        return {
            attr,
            setRef,
            events: {
                ...events,
                onChange: this.onChange.bind(this),
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resetFieldValue: resetFieldValue.bind(this, { setState: (val) => this.setState(val) }),
            fileName,
            isLoading,
            value,
            placeHolder
        };
    }

}
export default FieldFileContainer;