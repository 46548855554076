import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Hide from '../../../../public/hide.svg';
import Show from '../../../../public/show.svg';

import './PasswordInput.style';
/** @namespace Ennero/Component/Field/PasswordInput/Component */
export class PasswordInputComponent extends PureComponent {
    static propTypes = {
        type: PropTypes.string,
        setRef: PropTypes.func,
        attr: PropTypes.any,
        events: PropTypes.any,
        isDisabled: PropTypes.bool,
        showPassword: PropTypes.bool,
        toggleShowPassword: PropTypes.func,
    };

    static defaultProps = {
        type: 'text',
        setRef: () => {},
        toggleShowPassword: () => {},
        attr: {},
        events: {},
        isDisabled: false,
        showPassword: false,
    };

    render() {
        const {
            type,
            setRef,
            attr,
            events,
            isDisabled,
            showPassword,
            toggleShowPassword,
        } = this.props;

        return (
            <div block="PasswordInput" elem="Wrapper">
                <input
                    ref={(elem) => setRef(elem)}
                    disabled={isDisabled}
                    type={showPassword ? 'text' : type}
                    {...attr}
                    {...events}
                />
                <button type="button" onClick={toggleShowPassword}>
                    {showPassword ? (
                        <img src={Hide} alt="show" />
                    ) : (
                        <img src={Show} alt="show" />
                    )}
                </button>
            </div>
        );
    }
}
export default PasswordInputComponent;
