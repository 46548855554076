import { ReactElement } from 'react';

import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';

/** @namespace Ennero/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    render(): ReactElement {
        return (
            <div />
        )
    };
}

export default FooterComponent;
