/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

import FieldSelect from './FieldSelect.component';

/**
 * Field Select
 * @class FieldSelectContainer
 * @namespace Ennero/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    handleSelectExpand(event) {
        if (!this.isSelectDisabled()) {
            if (!event) {
                this.setState({ isExpanded: false });

                return;
            }

            const clickedItem = event.target;

            if (
                clickedItem.localName === 'li' ||
                clickedItem?.parentElement?.localName === 'li'
            ) {
                this.setState({ isExpanded: false });
            } else {
                this.setState(({ isExpanded }) => ({
                    isExpanded: !isExpanded,
                }));
            }
        }
        this.handleDropdownOpenDirection();
    }

    componentDidUpdate(prevProps) {
        const { selectedOptionIndex: prevSelectedOptionIndex } = this.state;
        const { options } = this.props;
        const selectedOptionIndex = this.fieldRef?.options.selectedIndex;
        const prevOptions = prevProps.options;

        if (prevSelectedOptionIndex !== selectedOptionIndex || prevOptions !== options) {
            this.isSelectedOptionAvailable();
        }
    }

    render() {
        return (
            <FieldSelect
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default FieldSelectContainer;
