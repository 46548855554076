import { PureComponent, ReactElement } from 'react';

import { footerMenuItemsScroll } from 'Util/base/Header';

import HomepageFooterComponent from './HomepageFooter.component';

/** @namespace Ennero/Component/HomepageFooter/Container */
export class HomepageFooterContainer extends PureComponent {
    componentDidMount(): void {
        footerMenuItemsScroll();
    }

    render(): ReactElement {
        return (
            <HomepageFooterComponent />
        )
    };
}

export default HomepageFooterContainer;
