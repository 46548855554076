/* eslint-disable no-console */
/* eslint-disable fp/no-loops */
import { isMobile } from 'Util/Mobile';
import  { getStoreState } from 'Util/Store';

/** @namespace Ennero/Route/HomePage/Script/addEventListenerForAccordionAndVideo */
export const addEventListenerForAccordionAndVideo = () => {

    // Select the node that will be observed for mutations
    const targetNode = document.getElementById("root");

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };


    const addAccordionEventListeners  =() => {
        const accordion = document.getElementsByClassName("accordion");
        let items;

        for (items = 0; items < accordion.length; items++) {
            accordion[items].addEventListener("click", function clickHandler() {
                const panel = this.nextElementSibling;
                const {maxHeight} = panel.style
                let activeItem;

                const activePanels = document.getElementsByClassName("active panel");
                for (activeItem = 0; activeItem < activePanels.length; activeItem++) {
                    activePanels[activeItem].style.maxHeight = null;
                    activePanels[activeItem].classList.toggle('active');
                }

                const activeAccordion = document.getElementsByClassName("accordion active");
                for (activeItem = 0; activeItem < activeAccordion.length; activeItem++) {
                    activeAccordion[activeItem].classList.toggle('active');
                }

                if (maxHeight) {
                    panel.style.maxHeight = null;
                    panel.classList.remove('active');
                    this.classList.remove("active");
                } else {
                    panel.style.maxHeight = `${panel.scrollHeight}px`;
                    panel.classList.add('active');
                    this.classList.add("active");
                }
            });
        }
    }

    const addVideoEventListener = () => {

        const videoElement = document.querySelector('.how-it-works-video video');
        const videoBackgroundElement = document.querySelector('.background video');

        if (videoElement) {
            videoElement.addEventListener('ended', () => {
              // Reset the video to the initial frame
              videoElement.currentTime = 0;
            });

            const isMobileAndIOS = getStoreState()?.ConfigReducer?.device?.isMobile && isMobile?.iOS()

            if(isMobileAndIOS) {
                videoElement.setAttribute('autoplay', 'ture')
            }
        }

        if (videoBackgroundElement) {
            videoBackgroundElement.loop = true;
        }
    };

    const addFAQToContainer = (callback, observer) => {
        const questionsContainer = document.getElementsByClassName('faq-questions-container');
        const questionList = document.getElementsByClassName('faq-questions-list');
        const content = questionList?.[0]?.textContent;
    
        console.log('%c observer entered', 'color: white; background-color: Purple; padding: 4px;');
        console.log('%c question container', 'color: white; background-color: violet; padding: 4px;', questionsContainer.length,  questionsContainer[0]);
        console.log('%c question list', 'color: white; background-color: violet; padding: 4px;', questionList.length, questionList[0]);
        console.log(`%c content to be inserted ${content}`, 'color: white; background-color: violet; padding: 4px;');

        if ((questionsContainer?.length > 0 && questionsContainer[0]) && (questionList?.length > 0 && questionList[0]) && (content.trim() !== '') ) {
            try {
                questionsContainer[0].insertAdjacentHTML('afterbegin', content);
                console.log('%c After insertion', 'color: white; background-color: Purple; padding: 4px;', questionsContainer[0]);
                addAccordionEventListeners();
                callback();
                observer.disconnect();
            }
            catch(e) {
                console.log('%c Error with insertion',e, 'color: white; background-color: Indigo; padding: 4px;');
            }
        }
    };
    

    const reInsertion = (__, observer) => {
        const questionsContainer = document.getElementsByClassName('faq-questions-container');

        if(!(questionsContainer?.[0]?.children?.length > 0)) {
            addFAQToContainer(() => {}, observer);
        }

        setTimeout(() => {
            observer.disconnect();
        }, 3000)
    };
    const checkForRemoval = new MutationObserver(reInsertion);



    // Callback function to execute when mutations are observed for accordion
    const accordionCallback = (__, observer) => {
        addFAQToContainer(() => {
            checkForRemoval.observe(targetNode, config);
        }, observer);
    };

    // Callback function to execute when mutations are observed for video
    const videoCallback = (__, observer) => {
        if (
            document.getElementsByClassName('how-it-works-video').length > 0 ||
            document.getElementsByClassName('background').length > 0
        ) {
            addVideoEventListener();
            observer.disconnect();
        }

    };

    // Create observer instances linked to their respective callback functions
    const accordionObserver = new MutationObserver(accordionCallback);
    const videoObserver = new MutationObserver(videoCallback);

    accordionObserver.observe(targetNode, config);

    videoObserver.observe(targetNode, config);
}
