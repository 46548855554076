/* eslint-disable react/no-danger */
import {
    CmsPageComponent as SourceCmsPageComponent,
} from 'SourceRoute/CmsPage/CmsPage.component';

/** @namespace Ennero/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {

    renderContent() {
        const {
            isLoading,
            cmsPage: { content },
        } = this.props;

        if (isLoading) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!content) {
            return null;
        }

        return  <div dangerouslySetInnerHTML={{__html: content}} />;
    }
}

export default CmsPageComponent;
