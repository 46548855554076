import { FieldComponent as SourceFieldComponent } from 'SourceComponent/Field/Field.component';
import { FieldType } from 'SourceComponent/Field/Field.config';
import FieldFile from 'SourceComponent/FieldFile';

import PasswordInput from './PasswordInput';
/** @namespace Ennero/Component/Field/Component */
export class FieldComponent extends SourceFieldComponent {
    renderMap = {
        // Checkboxes & Radio
        [FieldType.RADIO]: this.renderCheckboxOrRadio.bind(this),
        [FieldType.CHECKBOX]: this.renderCheckboxOrRadio.bind(this),
        [FieldType.MULTI]: this.renderCheckboxOrRadio.bind(this),

        // Default input
        [FieldType.EMAIL]: this.renderDefaultInput.bind(this),
        [FieldType.TEXT]: this.renderDefaultInput.bind(this),
        [FieldType.TIME]: this.renderDefaultInput.bind(this),
        [FieldType.DATETIME]: this.renderDefaultInput.bind(this),
        [FieldType.DATE]: this.renderDefaultInput.bind(this),
        [FieldType.PASSWORD]: this.renderPasswordtInput.bind(this),
        [FieldType.SUBMIT]: this.renderDefaultInput.bind(this),
        [FieldType.TEL]: this.renderDefaultInput.bind(this),
        [FieldType.NUMBER]: this.renderDefaultInput.bind(this),

        // Custom fields
        [FieldType.FILE]: this.renderFile.bind(this),
        [FieldType.SELECT]: this.renderSelect.bind(this),
        [FieldType.TEXTAREA]: this.renderTextArea.bind(this),
        [FieldType.BUTTON]: this.renderButton.bind(this),
        [FieldType.NUMBER_WITH_CONTROLS]:
            this.renderNumberWithControls.bind(this),
    };

    renderFile() {
        const {
            attr,
            events,
            setRef,
            validate,
            resetFieldValue,
            placeHolder,
            options,
        } = this.props;

        return (
            <FieldFile
              attr={ attr }
              events={ events  }
              setRef={ setRef }
              validate={ validate }
              resetFieldValue={ resetFieldValue }
              placeHolder = {placeHolder}
              options = {options}
            />
        );
    }

    renderPasswordtInput() {
        const { type, setRef, attr, events, isDisabled } = this.props;

        return (
            <PasswordInput
                setRef={setRef}
                isDisabled={isDisabled}
                type={type}
                attr={attr}
                events={events}
            />
        );
    }
}

export default FieldComponent;
