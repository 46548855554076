/* eslint-disable react/no-danger */
import {
    CmsBlockComponent as SourceCmsBlockComponent,
} from 'SourceComponent/CmsBlock/CmsBlock.component';

/**
 * Cms Block
 * @class CmsBlock
 * @namespace Ennero/Component/CmsBlock/Component
 */
export class CmsBlockComponent extends SourceCmsBlockComponent {
   

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled,
            },
            blockType,
        } = this.props;

        if (!content || disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
            >
                <div dangerouslySetInnerHTML={{__html: content}} />
            </div>
        );
    }
}

export default CmsBlockComponent;
