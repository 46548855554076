import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import PasswordInputComponent from './PasswordInput.component';
/** @namespace Ennero/Component/Field/PasswordInput/Container */
export class PasswordInputContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.string,
        setRef: PropTypes.func,
        attr: PropTypes.any,
        events: PropTypes.any,
        isDisabled: PropTypes.bool,
    };

    static defaultProps = {
        type: 'text',
        setRef: () => {},
        attr: {},
        events: {},
        isDisabled: false,
    };

    state = {
        showPassword: false,
    };

    containerFunctions = {
        toggleShowPassword: this.toggleShowPassword.bind(this),
    };

    toggleShowPassword() {
        const { showPassword } = this.state;
        this.setState({ showPassword: !showPassword });
    }

    render() {
        const { showPassword } = this.state;

        return (
            <PasswordInputComponent
                {...this.props}
                showPassword={showPassword}
                {...this.containerFunctions}
            />
        );
    }
}
export default PasswordInputContainer;
