/** @namespace Ennero/Component/PrivacypoliceAndTermsofsevices/Script/addObserverToRoot */
export const addObserverToRoot = (callbackFunction) => {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById("root");
    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = (__, observer) => {
        callbackFunction(observer);
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}

/** @namespace Ennero/Component/PrivacypoliceAndTermsofsevices/Script/injictStaticContentPopupButton */
export const injictStaticContentPopupButton = (className) => {
    addObserverToRoot((observer) => {
        if (document.querySelectorAll(`.${className}-button-container`).length > 0 && document.querySelectorAll(`.${className}-button`).length > 0) {
            const source = document.querySelectorAll(`.${className}-button`)[0];
            document.querySelectorAll(`.${className}-button-container`)[0].appendChild(source);
            observer.disconnect();
        }
    })
}