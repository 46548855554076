import { PureComponent } from 'react';

import './CloseIcon.style';

/** @namespace Ennero/Component/CloseIcon/Component */
export class CloseIconComponent extends PureComponent {
    render() {
        return (
            <span block='Close' elem='Icon'/>
        );
    }
}

export default CloseIconComponent;
