/** @namespace Ennero/Util/Base/Header/headerSticky */
export const headerSticky = (onStickyChange) => {
    // Get the header
    const header = document.querySelector('.Header');

    if (header && header instanceof HTMLElement) {
        // Get the offset position of the header
        const sticky = header.offsetTop;

        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        window.onscroll = function handleScroll() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
                onStickyChange(true);
            } else {
                header.classList.remove("sticky");
                onStickyChange(false);
            }
        };
    }
}

/** @namespace Ennero/Util/Base/Header/menuItemsScroll */
export const menuItemsScroll = (baseSelector) => {
    document.querySelectorAll(`${baseSelector} a[href*="#"]`).forEach(anchor => {
        anchor.addEventListener('click',  (e) => {

            e.preventDefault();
            let targetElement = e.target

            if (!targetElement.href) {
                targetElement = targetElement.parentElement
            }
            const targetId = targetElement.href.split('#');
                        const href= targetId[1];
            const sectionElement = document.querySelector(`.${href}`);

            if (sectionElement) {
                // Get the bounding client rect of the element and header
                const elementRect = sectionElement.getBoundingClientRect();
                const headerHeight = document.querySelector('.Header').getBoundingClientRect().height;

                // Calculate the offset position of the element
                const offsetPosition = elementRect.top - headerHeight;

                // Scroll to the element with the offset
                window.scrollBy({
                    top: offsetPosition,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        });
    });
}


/** @namespace Ennero/Util/Base/Header/headerMenuItemsScroll */
export const headerMenuItemsScroll = () => {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById("root");

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (__, observer) => {
        if (document.querySelectorAll('.header-menu a').length > 0) {
            menuItemsScroll('.Header .header-menu');
            observer.disconnect();
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}

/** @namespace Ennero/Util/Base/Header/footerMenuItemsScroll */
export const footerMenuItemsScroll = () => {

    // Select the node that will be observed for mutations
    const targetNode = document.getElementById("root");

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (__, observer) => {
        if (document.querySelectorAll('.footer-menu-links a').length > 0) {
            menuItemsScroll('.footer-menu-links');
            observer.disconnect();
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}
