import PropTypes from 'prop-types'
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import CmsBlock from '../CmsBlock';

import './StaticContentInPopup.style';

/** @namespace Ennero/Component/StaticContentInPopup/Component */
export class StaticContentInPopupComponent extends PureComponent{
    static propTypes = {
        showStaticContentPopup: PropTypes.func.isRequired,
        popupId: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
    };

    renderContent() {
        const {showStaticContentPopup, popupId} = this.props

        return(
            <button block='AccountTabs' onClick={showStaticContentPopup} elem={`Button ${popupId}-button`}>{__(popupId)}</button>
        )
    }

    renderPopup() {
        const {popupId , isMobile} = this.props;

        return (
            <Popup
                id={popupId}
                mix={{ block: 'static-content', elem: 'Popup' }}
                classAnimationIn={isMobile ? "animate__slideInUp" : "animate__slideInRight"}
                showContent
            >
                <CmsBlock identifier={popupId} />
            </Popup>
        )
    }

    render() {
        return (
        <>
            {this.renderContent()}
            {this.renderPopup()}
        </>
    )}
};

export default StaticContentInPopupComponent;
