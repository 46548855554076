/* eslint-disable jsx-a11y/label-has-associated-control */
import { FieldType } from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import { FieldFileComponent as SourceFieldFileComponent } from 'SourceComponent/FieldFile/FieldFile.component';

import Attached from '../../style/icons/logos/attached.svg';
import Attachment from '../../style/icons/logos/attachment.svg';

import './FieldFile.extenion.style';

/** @namespace Ennero/Component/FieldFile/Component */
export class FieldFileComponent extends SourceFieldFileComponent {
    renderFileLabel() {
        const {
            attr: { id = '' } = {},
            fileName = '',
            isLoading = false,
            placeHolder
        } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (fileName) {
            return (
                <label htmlFor={id} block="LableField" elem="LableWithFile">
                    <img src={Attached} alt="" />
                    <div block="LableField" elem="FileNameDiv">
                        <p block="LableField" elem="FileName">
                            {fileName}
                        </p>
                    </div>
                </label>
            );
        }

        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        return (
            <label htmlFor={id} block="LableField" elem="LableWithFile">
                <img src={Attachment} alt="" />
                <p>{placeHolder || __('Attach Document')}</p>
            </label>
        );
    }

    render() {
        const {
            attr = {},
            attr: { accept = '' } = {},
            events = {},
            setRef,
        } = this.props;
        const allowedFieldTypes = (accept || '')
            .split(',')
            .map((type = '') => type.split('/').slice(-1)[0])
            .join(', ');

        return (
            <>
                <input
                    ref={(elem) => setRef(elem)}
                    type={FieldType.FILE}
                    {...attr}
                    {...events}
                />
                {this.renderFileLabel()}
                {allowedFieldTypes.length > 0 &&
                    this.renderSubLabel(allowedFieldTypes)}
            </>
        );
    }
}

export default FieldFileComponent;
