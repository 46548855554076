import PropTypes from 'prop-types'
import { PureComponent } from 'react';

import { modalNames } from 'Src/modal/modal.config';

import PrivacypoliceAndTermsofsevicesComponent from './PrivacypoliceAndTermsofsevices.component';
import { injictStaticContentPopupButton } from './PrivacypoliceAndTermsofsevices.script';

/** @namespace Ennero/Component/PrivacypoliceAndTermsofsevices/Container */
export class PrivacypoliceAndTermsofsevicesContainer extends PureComponent{
    static propTypes = {
        useObserverToInject: PropTypes.bool.isRequired
    };

    componentDidMount() {
        const {useObserverToInject} = this.props

        if (useObserverToInject) {
            injictStaticContentPopupButton(modalNames.PRIVACY_POLICY_POPUP);
            injictStaticContentPopupButton(modalNames.TERM_OF_SERVICE_POPUP);
        }
    }

    render() {
        return (
            <PrivacypoliceAndTermsofsevicesComponent />
    )}
};

export default PrivacypoliceAndTermsofsevicesContainer;