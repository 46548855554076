import { PureComponent } from 'react';

import StaticContentInPopup from 'Component/StaticContentInPopup';
import { modalNames } from 'Src/modal/modal.config';

/** @namespace Ennero/Component/PrivacypoliceAndTermsofsevices/Component */
export class PrivacypoliceAndTermsofsevicesComponent extends PureComponent{

    renderContent() {

        return(
            <div block='AccountTabs' elem="AccountButtonsHeader static-popup-buttons">
                <div block='AccountTabs' elem="Buttons">
                    <StaticContentInPopup popupId={modalNames.PRIVACY_POLICY_POPUP} />
                    <StaticContentInPopup popupId={modalNames.TERM_OF_SERVICE_POPUP} />
                </div>
            </div> 
        )
    }

    render() {
        return (
        <>
            {this.renderContent()}
        </>
    )}
};

export default PrivacypoliceAndTermsofsevicesComponent;