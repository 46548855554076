import { PureComponent, ReactElement } from 'react';

import CmsBlock from 'SourceComponent/CmsBlock';

/** @namespace Ennero/Component/HomepageFooter/Component */
export class HomepageFooterComponent extends PureComponent {
    render(): ReactElement {
        return (
            <div className="footer-container">
                <CmsBlock identifier="Fuelee-Footer-Links" />
            </div>
        )
    };
}

export default HomepageFooterComponent;
