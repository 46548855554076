import { connect } from 'react-redux';

import CmsBlockQuery from 'Query/CmsBlock.query';
import {
    CmsBlockContainer as SourceCmsBlockContainer,
} from 'SourceComponent/CmsBlock/CmsBlock.container';
import { fetchQuery } from 'Util/Request/Query';

import { updateCmsBlock } from '../../store/Cms/Cms.action';

/** @namespace Ennero/Component/CmsBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCmsBlock: (payload) => dispatch(updateCmsBlock(payload)),
});

/** @namespace Ennero/Component/CmsBlock/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cmsBlocks : state.CmsReducer.cmsBlock,
})

/**
 * Cms Block
 * @class CmsBlock
 * @namespace Ennero/Component/CmsBlock/Container
 */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static defaultProps = {
        blockType: '',
        cmsBlock : null,
        cmsBlocks : {}
    };

    componentDidMount() {
        const { cmsBlocks, identifier } = this.props;

        if(cmsBlocks[identifier]) {
            this.setState({ cmsBlock: cmsBlocks[identifier] });
        } else if(!this.props.cmsBlock){
            this._getCmsBlock();
        } else{
            this.setState({ cmsBlock: this.props.cmsBlock });
        }
    }

    _getCmsBlock() {
        const { identifier, updateCmsBlock } = this.props;
        const query = CmsBlockQuery.getQuery({ identifiers: [identifier] })        
        fetchQuery(query)
            .then(
                /** @namespace Ennero/Component/CmsBlock/Container/CmsBlockContainer/_getCmsBlock/then/catch/fetchQuery/then */
                ({cmsBlocks}) => {
                    const {items} = cmsBlocks
                    const cmsBlock = items[0]
                    this.setState({ cmsBlock });
                    updateCmsBlock({[cmsBlock.identifier]: cmsBlock})
                }
            )
            .catch(
                /** @namespace Ennero/Component/CmsBlock/Container/CmsBlockContainer/_getCmsBlock/then/catch */
                (error) =>
                    // eslint-disable-next-line no-console
                    console.log('error ', error)
            );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CmsBlockContainer);
