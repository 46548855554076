import { PureComponent } from 'react';

/** @namespace Ennero/Component/CheckIcon/Component */
export class CheckIconComponent extends PureComponent {

    render() {

        return (
            <svg
                block="CheckIcon"
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13 2L5.23529 10L2 6.66667"
                    stroke="#7AC760"
                    strokeWidth="2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default CheckIconComponent;
