import PropTypes from 'prop-types'
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import StaticContentInPopupComponent from './StaticContentInPopup.component';


/** @namespace Ennero/Component/StaticContentInPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Ennero/Component/StaticContentInPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
});

/** @namespace Ennero/Component/StaticContentInPopup/Container */
export class StaticContentInPopupContainer extends PureComponent{
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        popupId: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired
    };
    
    containerFunctions = {
        showStaticContentPopup: this.showStaticContentPopup.bind(this),
    };

    __construct(props) {
        super.__construct?.(props);
    }

    showStaticContentPopup() {
        const { showPopup, popupId } = this.props

        showPopup(popupId, {
            action: 'static_content',
            title: __(popupId),
        });
    }

    containerProps() {
        const {popupId , isMobile} = this.props

        return {
            popupId,
            isMobile,
        };
    }

    render() {
        return (
            <StaticContentInPopupComponent
                {...this.containerProps()}
                {...this.containerFunctions}
            />
    )}
};

export default (connect(mapStateToProps, mapDispatchToProps)(StaticContentInPopupContainer));
