/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CheckIcon from 'Component/CheckIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import { FieldSelectComponent as SourceFieldSelectComponent } from 'SourceComponent/FieldSelect/FieldSelect.component';
import { noopFn } from 'Util/Common';

import './FieldSelect.extenion.style';

/**
 * Field Select
 * @class FieldSelect
 * @namespace Ennero/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelectComponent {
    renderNativeOption(option) {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isAvailable = true,
            displayLabel,
        } = option;

        const { isDisabled } = this.props;

        return (
            <option
                key={id}
                id={String(id)}
                value={value}
                disabled={disabled || isDisabled || !isAvailable}
            >
                {displayLabel ||
                    (label || subLabel ? `${label} ${subLabel}` : '')}
            </option>
        );
    }

    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true,
            selected,
            description,
        } = option;

        const { isExpanded, handleSelectListOptionClick } = this.props;

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{
                    isDisabled: !isAvailable,
                    isExpanded,
                    isPlaceholder,
                    isHovered,
                }}
                key={id}
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={`o${id}`}
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onMouseDown={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onTouchStart={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? 0 : -1}
            >
                <div block='wrapper'>
                    <div block="LabelAndSubLabel">
                        <div block="Label">{label}</div>
                        <div block="SubLabel">{subLabel && subLabel}</div>
                    </div>
                    {description &&<div block="Description">{description}</div>}
                </div>
                <div block="CheckIcon">
                    {selected && !isPlaceholder && <CheckIcon />}
                </div>
            </li>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
        } = this.props;

        return (
            <ClickOutside onClick={handleSelectExpandedExpand}>
                <div
                    id={`${id}_wrapper`}
                    block="FieldSelect"
                    mods={{ isExpanded }}
                    onClick={!isDisabled ? handleSelectExpand : noopFn}
                    onKeyPress={!isDisabled ? handleSelectListKeyPress : noopFn}
                    role="button"
                    tabIndex={0}
                    aria-label="Select dropdown"
                    aria-expanded={isExpanded}
                >
                    <div block="FieldSelect" elem="Clickable">
                        {this.renderSortSelect()}
                        {this.renderNativeSelect()}
                        <span block="ChevronIconWrapper" elem="Clickable">
                            <ChevronIcon
                                direction={
                                    isExpanded
                                        ? Directions.TOP
                                        : Directions.BOTTOM
                                }
                            />
                        </span>
                    </div>
                    {this.renderOptions()}
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
