import { connect } from 'react-redux';

import HomepageFooter from 'Component/HomepageFooter';
import PrivacypoliceAndTermsofsevices from 'Component/PrivacypoliceAndTermsofsevices';
import { DEFAULT_STATE_NAME } from 'SourceComponent/NavigationAbstract/NavigationAbstract.config';
import CmsPage from 'SourceRoute/CmsPage';
import {
    HomePageContainer as SourceHomePageContainer, 
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';

import { isPageEnabled } from '../../util/Auth/IsSignedIn';
import { addEventListenerForAccordionAndVideo } from './HomePage.script';

/** @namespace Ennero/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    componentDidMount() {
        const { changeHeaderState } = this.props;

        isPageEnabled(true);

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false,
        });
        addEventListenerForAccordionAndVideo();
    }

    render() {
        return (
            <div
                block="HomePage"
                className="animate__animated animate__slideInLeft"
            >
                <CmsPage {...this.containerProps()} />
                <HomepageFooter />
                <PrivacypoliceAndTermsofsevices useObserverToInject />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
