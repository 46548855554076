import { PopupComponent as SourcePopupComponent } from 'SourceComponent/Popup/Popup.component';
import { toggleScroll } from 'Util/Browser';

import './Popup.style';

/** @namespace Ennero/Component/Popup/Component */
export class PopupComponent extends SourcePopupComponent {

    renderBackButton() {
        return (
            <button
                block="Popup"
                elem="BackBtn"
                aria-label={__('Back')}
                onClick={this.hidePopupAndGoBack}
            >
                <div>{__('Back')}</div>
            </button>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    freezeScroll() {
        toggleScroll(false);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    unfreezeScroll() {
        toggleScroll(true);
    }

    renderContent() {
        const { children, contentMix , classAnimationIn, showContent} = this.props;
        const isVisible = this.getIsVisible();
        
        if (!isVisible && !showContent) {
            return null;
        }

        return (
            <div
                block="Popup"
                elem="Content"
                mix={contentMix}
                className={`animate__animated ${classAnimationIn}`}
            >
                <header block="Popup" elem="Header">
                    {this.renderBackButton()}
                    {this.renderTitle()}
                    {this.renderCloseButton()}
                </header>
                {children}
            </div>
        );
    }
}

export default PopupComponent;
