import { FieldType } from '@scandipwa/scandipwa/src/component/Field/Field.config';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { validate } from 'Util/Validator/Validator';

/** @namespace Ennero/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {

    validate(data) {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} }, type, attr: { name } = {},
        } = this.props;
        const { showLengthError } = this.state;
        const newValidRule = this.handleShowLengthError();
        const value = type === FieldType.CHECKBOX || type === FieldType.RADIO
            ? !!(this.fieldRef)?.checked
            : this.fieldRef?.value;

        if (!value && value !== '') {
            return false;
        }

        const response = validate(type === FieldType.FILE
            && typeof value === 'string'
            ? value.toLowerCase()
            : value, newValidRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && typeof output !== 'boolean') {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (output.errorMessages
                && typeof value === 'string'
                && maxValidLength
                && value.length > maxValidLength
                && !showLengthError
            ) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('PleaseEnterNoMoreThan', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output && value === '' ? false : output });

        return output && value === '' ? false : output;
    }

containerProps() {
    const {
        events,
        validateOn,
        type,
        attr,
        isDisabled,
        mix,
        value,
        options,
        showErrorAsLabel,
        label,
        subLabel,
        addRequiredTag,
        changeValueOnDoubleClick,
        isSortSelect,
        placeHolder
    } = this.props;
    const { validationResponse } = this.state;
    const { validate } = this.containerFunctions;

    // Surrounds events with validation
    const newEvents = { ...events };

    validateOn.forEach((eventName) => {
        const { [ eventName]: baseEvent } = events;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newEvents[ eventName ] = baseEvent
            ? this.validateOnEvent.bind(this, baseEvent) : validate;
    });

    return {
        type,
        attr,
        value,
        isDisabled,
        mix,
        options,
        showErrorAsLabel,
        label,
        subLabel,
        addRequiredTag,
        changeValueOnDoubleClick,
        isSortSelect,
        validationResponse,
        resetFieldValue: this.resetFieldValue.bind(this),
        events: newEvents,
        setRef: this.setRef.bind(this),
        placeHolder
    };
}

}

export default FieldContainer;